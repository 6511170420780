import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

function Text({ name, label, validate, errors, touched, type }) {
  return (
    <div className="form__field">
      <label className="form__label" htmlFor={name}>{label}</label>
      <Field id={name} name={name} type={type} validate={validate} />
      {errors[name] && touched[name] && <div className="form__error">{errors[name]}</div>}
    </div>
  );
}

Text.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  type: PropTypes.string,
};

Text.defaultProps = {
  validate: null,
  type: 'text',
};

export default Text;
