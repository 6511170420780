import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

function Checkbox(props) {
  const { registerField, unregisterField } = useFormikContext();
  const { onChange, value, name, label, errors, touched, validate } = props;

  useEffect(() => {
    registerField(name, { validate });
    return () => {
      unregisterField(name);
    };
  }, [registerField, unregisterField, name]);

  return (
    <div className="form__field">
      <label className="form__checkbox" htmlFor={name}>
        <input
          type="checkbox"
          checked={value}
          name={name}
          id={name}
          onChange={(e) => {
            onChange(name, e.target.checked);
          }}
        />
        {label}
      </label>
      {errors[name] && touched[name] && <div className="form__error">{errors[name]}</div>}
    </div>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  validate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
};

Checkbox.defaultProps = {
  validate: null,
  value: false,
};

export default Checkbox;
