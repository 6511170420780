import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

function Select({ options, name, label, validate, errors, touched }) {
  return (
    <div className="form__field">
      <label className="form__label" htmlFor={name}>{label}</label>
      <Field
        id={name}
        name={name}
        component="select"
        validate={validate}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Field>
      {errors[name] && touched[name] && <div className="form__error">{errors[name]}</div>}
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
};

Select.defaultProps = {
  validate: null,
};

export default Select;
