import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

function NumberField({ name, label, validate, errors, touched }) {
  return (
    <div className="form__field">
      {label && (<label className="form__label" htmlFor={name}>{label}</label>)}
      <Field id={name} name={name} type="number" validate={validate} />
      {errors[name] && touched[name] && <div className="form__error">{errors[name]}</div>}
    </div>
  );
}

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
};

NumberField.defaultProps = {
  validate: null,
};

export default NumberField;
