export const validateRequired = (message) => (value) => {
  return value ? null : message;
};

export const validateMinLength = (message, minLength) => (value) => {
  return value && value.length >= minLength ? null : message;
};

export const validatePersons = (message) => (value) => {
  return value && typeof value === 'number' && value >= 30 ? null : message;
};

export const validateEmail = (message) => (value) => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? null : message;
};

export const validateNumber = (message) => (value) => {
  return !value || typeof value === 'number' ? null : message;
};

export const phoneValidator = (message) => (value) => {
  return value && /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i.test(value) ? null : message;
};

export const validateBotField = (message) => (value) => {
  return !value ? null : message;
};
